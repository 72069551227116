import BaseAPI from "./BaseAPI";
import config from "../config";

const BASE_URL = config.url;

export default class BackendAPI {
  locker_id = undefined;
  merchant_id = undefined;
  code = undefined;

  static async locker() {
    this.locker_id = localStorage.getItem("@config-app/id");
    this.merchant_id = localStorage.getItem("@config-app/merchant-id");
    this.code = localStorage.getItem("@config-app/locker-code");
    return BaseAPI.get(`${BASE_URL}/locker/${this.locker_id}`, {
      code: this.code,
    });
  }

  static async checkCode(pin, is_supplier = false) {
    return BaseAPI.get(`${BASE_URL}/checkcode/${pin}`, {
      merchant_id: this.merchant_id,
      locker_id: this.locker_id,
      is_supplier,
    });
  }

  static async externalOrderDetails(number) {
    return BaseAPI.get(`${BASE_URL}/order-details/${number}`, {
      merchant_id: this.merchant_id,
      locker_id: this.locker_id,
    });
  }

  static async order(number) {
    return BaseAPI.get(`${BASE_URL}/order/`, {
      merchant_id: this.merchant_id,
      number,
      locker_id: this.locker_id,
    });
  }

  static async reversesOrder(data) {
    return BaseAPI.get(`${BASE_URL}/locker-orders/`, data);
  }

  static async createOrder(data) {
    return BaseAPI.post(`${BASE_URL}/order/`, data);
  }

  static async allocate(data) {
    return BaseAPI.post(`${BASE_URL}/allocate/`, data);
  }

  static async deallocate(data) {
    return BaseAPI.post(`${BASE_URL}/deallocate/`, data);
  }

  static async deallocateContainer(data) {
    return BaseAPI.put(`${BASE_URL}/deallocate/`, data);
  }

  static async cancelSupply(data) {
    return BaseAPI.delete(`${BASE_URL}/order/`, data);
  }
}
