import BackendAPI from "../services/BackendAPI";
import User from "../models/User";
import Order from "../models/Order";
import Locker from "../models/Locker";
import { extendObservable } from "mobx";
import config from "../config";

const { merchant_id, locker_code, locker_id, test } = config;

const emailTest = "stoom@stoom.com.br";

const UNAVAILABLE = "UNAVAILABLE";
const ALLOCATED = "ALLOCATED";

// Store to communicate with the backend.
export default class LockerStore {
  constructor() {
    extendObservable(this, {
      locker: null,
      order: null,
      user: null,
      externalOrder: null,
      reverses: [],
    });
    this.syncLocker();
  }

  reset() {
    this.user = null;
    this.order = null;
    this.externalOrder = null;
    this.reverses = [];
  }

  resetOrder() {
    this.order = null;
    this.externalOrder = null;
    let allocations = this.getStatusContainers(ALLOCATED);
    allocations.map((c) =>
      this.setStatusContainer(c.number, "AVAILABLE", allocations)
    );
  }

  // get info about locker and containers.
  async syncLocker() {
    try {
      const result = await BackendAPI.locker();
      this.locker = new Locker(result.data);
      this.locker_id = localStorage.getItem("@config-app/id");
      this.merchant_id = localStorage.getItem("@config-app/merchant-id");
      this.locker_code = localStorage.getItem("@config-app/locker-code");
      return result;
    } catch (err) {
      console.log("err", err);
    }
  }

  // check if pin is valid.
  // pin can be user number or order number.
  async checkCode(pin, is_supplier) {
    let letter = "P";
    try {
      const result = await BackendAPI.checkCode(pin, is_supplier);
      if (result.data.error) {
        return { success: false, letter: letter, error: result.data.error };
      }
      if (result.data.type === "SUPPLIER") {
        letter = "U";
        this.user = new User(result.data);
        await this.syncLocker();
        await this.reversesOrder();
      } else {
        this.order = new Order(result.data);
      }
      return { success: true, letter: letter };
    } catch (err) {
      console.log("err", err);
    }
    return { success: false, letter: letter, error: "Algo deu errado. Por favor, tente novamente ou peça ajuda a um atendente em loja." };
  }

  // External order.
  // Searches for information from a linked order in a merchant.
  async externalOrderDetails(number, user_type) {
    try {
      const result = await BackendAPI.externalOrderDetails(
        number,
        this.merchant_id,
        user_type
      );
      // if(result.data.error){
      //   return { success: false, error : result.data.error };
      // }
      this.externalOrder = new Order(result.data);
      return { success: true, data: result.data };
    } catch (err) {
      console.log("err", err);
      return { success: false, error: err.response["data"]["error"] };
    }
  }

  // Internal order.
  // Searches for information from a linked order in a merchant.
  async order(number) {
    try {
      const result = await BackendAPI.order(number, this.merchant_id);
      this.order = new Order(result.data);
      return true;
    } catch (err) {
      console.log("err", err);
    }
    return false;
  }

  // create internal order.
  async createOrder() {
    try {
      const result = await BackendAPI.createOrder({
        merchant_id: this.merchant_id,
        status: "READY",
        number: this.externalOrder.number,
        date: this.externalOrder.date,
        customer_name: this.externalOrder.customer.name,
        customer_email: this.externalOrder.customer.email,
      });
      this.order = new Order({
        ...this.externalOrder,
        ...result.data,
      });
      return true;
    } catch (err) {
      console.log("err", err);
    }
    return false;
  }

  /* Allocate containers. */
  // Order ainda é External Order.
  async allocate() {
    let { cpf, type, name, email } = this.externalOrder.customer;
    let number = this.externalOrder.number;

    if (test) {
      email = emailTest;
    }

    try {
      await BackendAPI.allocate({
        containers: this.allocatedDoors(),
        order: { number },
        customer: { cpf, type, name, email },
        merchant_id: this.merchant_id,
        locker_id: this.locker_id,
      });
      return true;
    } catch (err) {
      console.log(err);
    }
    return false;
  }

  /**
   * Cancel Supply
   * Quando o fornecedor decide, por algum motivo, cancelar o abastecimento
   * NAO USADO POR ENQUANTO
   */
  async cancelSupply() {
    try {
      //await this.deallocateOrder();
      const result = await BackendAPI.cancelSupply({
        order_id: this.order.id,
        merchant_id: this.merchant_id,
        locker_id: this.locker_id,
      });
      if (result.data.error) {
        return { success: false, error: result.data.error };
      }
      return { success: true, data: result.data };
    } catch (err) {
      console.log("err", err);
    }
    return { success: false, error: "Erro ao enviar código" };
  }

  // Deallocate order.
  // Order já foi utilizado e agora é desalocado.
  async deallocateOrder() {
    try {
      await BackendAPI.deallocate({
        order_id: this.order.id,
        merchant_id: this.merchant_id,
        locker_id: this.locker_id,
      });
      return true;
    } catch (err) {
      console.log(err);
    }
    return false;
  }

  // Deallocate order.
  // Dono do pedido não foi buscar e order vai ser desocupado.
  async deallocateReverseOrder() {
    try {
      await BackendAPI.deallocate({
        order_id: this.order.id,
        merchant_id: this.merchant_id,
        locker_id: this.locker_id,
        reverse: true,
        admin_id: this.user.id,
      });
      return true;
    } catch (err) {
      console.log(err);
    }
    return false;
  }

  // Deallocate container
  // Desaloca item a item dentro do locker.
  async deallocateContainer(container_id) {
    try {
      await BackendAPI.deallocateContainer({
        order_id: this.order.id,
        container_id,
        merchant_id: this.merchant_id,
        locker_id: this.locker_id,
      });
      this.setStatusContainerById(
        container_id,
        "PICKING_UP",
        this.order.containers
      );
      return true;
    } catch (err) {
      console.log(err);
    }
    return false;
  }

  // busca todas as ordens em reversa.
  async reversesOrder() {
    try {
      const result = await BackendAPI.reversesOrder({
        merchant_id: this.merchant_id,
        status: "READY",
        code: this.locker_code,
        reverse: true,
      });
      this.reverses = result.data.map((order) => new Order(order));
    } catch (err) {
      this.reverses = [];
      console.log(err);
    }
  }

  /***************************************************************************/
  /**METHODS******************************************************************/
  /***************************************************************************/

  stockedDoors(current) {
    if (!this.order) return [];
    return this.order.containers.filter(
      ({ number, status }) => status === UNAVAILABLE || current === number
    );
  }

  emptyDoors(stockedDoors) {
    if (!this.order) return [];
    return this.order.containers.filter((item) => !stockedDoors.includes(item));
  }

  // stockedDoorsRetrieveOrder(current) {
  //   if (!this.order) return [];
  //   return this.order.containers.filter(
  //     ({ number, status }) => status === UNAVAILABLE || current === number
  //   );
  // }

  // emptyDoorsRetrieveOrder(stockedDoors) {
  //   if (!this.order) return [];
  //   return this.order.containers.filter((item) => !stockedDoors.includes(item));
  // }

  orderCompleted(current) {
    return this.order && !this.stockedDoors(current).length;
  }

  allocateContainer(number) {
    this.setStatusContainer(number, ALLOCATED, this.locker.containers);
  }

  setStatusContainer(number, newStatus, containers) {
    const container = containers.find((c) => c.number === number);
    if (container) {
      container.status = newStatus;
    }
  }

  setStatusContainerById(id, newStatus, containers) {
    const container = containers.find((c) => c.id === id);
    if (container) {
      container.status = newStatus;
    }
  }

  hasReversesOrder() {
    return this.reverses && this.reverses.length;
  }

  getUser() {
    return this.user || (this.order && this.order.customer);
  }

  allocatedDoors() {
    return this.getStatusContainers(ALLOCATED) || [];
  }

  getStatusContainers(status) {
    return this.locker.containers.filter(
      (container) => container.status === status
    );
  }
}
